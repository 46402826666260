/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser } from "@remix-run/react";
import { StrictMode, startTransition } from "react";
import { hydrateRoot } from "react-dom/client";
import { getBrowserEnvVar } from "./root";

let SENTRY_DSN = getBrowserEnvVar("PUBLIC_SENTRY_DSN");
if (SENTRY_DSN) {
  import("./common/sentry.client.tsx").then(({ initSentry }) => initSentry());
}

startTransition(() => {
  hydrateRoot(
    // @ts-ignore
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});
